import React from "react";
import TypeAnimation from "react-type-animation";
import { motion } from "framer-motion";
import "./About.css";
import "../../components/atoms/PrimaryBtn/PrimaryBtn.css";
import "../shared/Shared.css";
import { BottomLine, MySkill, Education } from "../../components";
import { FaDownload } from "react-icons/fa";
import fillosoftlogo from "../../assets/fillosoftlogo.png"; 


const About = () => {
  return (
    <div className="parent pt-16 my-16"
      style={{
        backgroundImage: 'url("https://img.freepik.com/free-vector/white-background-with-wavy-line_361591-1205.jpg?w=1380&t=st=1699279318~exp=1699279918~hmac=f2c80d2c40888d83ee8228cf4f628732d3717badd2785e95d670bc017c803d64")',
        backgroundSize: 'cover',
        minHeight:'75vh'
      }}>
      <div className="">
        <motion.div
          className="mb-10"
          initial={{ y: -200, opacity: 0 }}
          animate={{
            y: 0,
            opacity: 1,
            transition: { duration: 1, type: "spring" },
          }}
        >
          <h1 className="text-4xl font-semibold drop-shadow-md text-center">
            About <span className="text-primary">Us</span>
          </h1>
          <BottomLine />
        </motion.div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-1">
          <motion.div
            initial={{ x: -200, opacity: 0 }}
            animate={{
              x: 0,
              opacity: 1,
              transition: { duration: 1, delay: 1.25 },
            }}
          >
            <img
              src={fillosoftlogo}
              alt="Fillosoft"
              className="p-12 w-70 h-70 transform translate-y-[-12%]"
              title="Fillosoft"
            />

          </motion.div>
          <motion.div
            className=""
            initial={{ x: 200, opacity: 0 }}
            animate={{
              x: 0,
              opacity: 1,
              transition: { duration: 1, delay: 1.25 },
            }}
          >
            <div className="my-4">
          <TypeAnimation
            className="text-2xl text-primary font-bold translate-y-[-80%] sm:translate-y-[-0%]"
            cursor={true}
            sequence={[
              "Welcome to Fillosoft, ",
              2000,
              "your digital gateway to web excellence!",
              2000,
            ]}
            wrapper="div"
            repeat={Infinity}
          />
        </div>
            <p style={{color:'black'}} className=" font-medium text-center ">
              At Fillosoft, we are passionate about creating innovative and dynamic web solutions that empower
              businesses and individuals to thrive in the digital landscape. We are thrilled to have you visit
              our website and explore the world of web development with us.
              Our team of dedicated professionals is committed to delivering top-notch
              web development services, tailored to your unique needs and goals.
              Whether you're looking to establish a strong online presence, revamp your existing website,
              or embark on an exciting e-commerce journey, Fillosoft is here to make your vision a reality.

            </p>
            <br />

            <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-12 gap-y-4 mt-8 text-center translate-y-[-50%] sm:translate-y-[-0%] sm:mb-2 md:text-left ">
              <h2 className="font-medium">
                <span className="mr-2 text-primary">Phone : </span>+91 70023 73380
              </h2>
              <h2 className="font-medium">
                <span className="mr-2 text-primary">Email : </span>
                support@fillosoft.com
              </h2>
              <h2 className="font-medium">
                <span className="mr-2 text-primary">Head Office : </span>Pragjyotishnagar Bylane-12,Jalukbari,Guwahati-11,Assam
              </h2>
              <h2 className="font-medium">
                <span className="mr-2 text-primary">Registered Office: </span>Vill-Kshudradimu. PO Kshudradimu, Khudradimu, Rangia Block, Kamrup- 7 81382, Assam. India, Guwahati, Assam, PIN: 781382
              </h2>
            </div>
          </motion.div>
        </div>
      </div>

      {/* <MySkill /> */}

    </div>
  );
};

export default About;
