import React from "react";
import { FaTwitter, FaLinkedin, FaGithub, FaInstagram } from "react-icons/fa";
import { Link } from "react-router-dom";

const Footer = () => {
  const year = new Date();

  return (
    <>
      <footer
        className="w-full text-center p-6 bg-accent"
        style={{
          backgroundImage: 'url("https://res.cloudinary.com/dpx9xzgf8/image/upload/v1699957243/fillosoft/nxcmrwd1stekwda0v6tj.jpg")',
          backgroundSize: 'cover',
          borderTop:'1px solid grey'
          // backgroundColor:'#00ABD7'
        }}      >
        <div className="flex items-center justify-center mb-6">
          <a
            className="inline-block mx-2"
            // href="https://twitter.com/GilbertHutapeaa"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaTwitter className="text-3xl text-[white] hover:text-[black] hover:-translate-y-1.5 shadow-lg mx-1 duration-300"
/>
          </a>
          <a
            className="inline-block mx-2"
            href="https://www.linkedin.com/in/jyoti-prakash-bora-415559231/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaLinkedin className="text-3xl text-[white] hover:text-[black] hover:-translate-y-1.5 shadow-lg mx-1 duration-300" />
          </a>
          <a
            className="inline-block mx-2"
            // href="https://www.github.com/berthutapea"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaGithub className="text-3xl text-[white] hover:text-[black] hover:-translate-y-1.5 shadow-lg mx-1 duration-300" />
          </a>
          <a
            className="inline-block mx-2"
            href="https://www.instagram.com/fillo_soft/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaInstagram className="text-3xl text-[white] hover:text-[black] hover:-translate-y-1.5 shadow-lg mx-1 duration-300" />
          </a>
        </div>
        <div className="hidden  md:flex items-center justify-center mb-6 flex-wrap">
          <a
            className="text-xl inline-bloc mx-6 text-white"
            target="_blank"
            rel="noopener noreferrer"
          >
            About Us
          </a>
          <a
            className="text-xl inline-bloc mx-6 text-white"
            target="_blank"
            rel="noopener noreferrer"
          >
            Contact
          </a>
          <Link to={"/TermsAndConditions"}
                      className="text-xl inline-bloc mx-6 text-white"

          >
          Terms And Conditions
          </Link>
        </div>

        <div className="w-full h-[2px] bg-white"></div>
        <div className=" mt-4" style={{textAlign:'left'}}>
          <p className="text-white ">&copy; Copyright All Rights Reserved {year.getFullYear()}</p>
          <p className="text-white">
            Developed by{" "}
            <a
              href="https://www.fillosoft.com/"
              className="text-white hover:underline"
              target="blank"
              style={{fontWeight:'bold'}}
            >
              Fillosoft
            </a>
          </p>
        </div>
      </footer>
    </>
  );
};

export default Footer;
