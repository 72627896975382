import React, { useEffect, useState } from 'react';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import { BottomLine } from '../../components';




const images = [
  {
    original: 'https://images.pexels.com/photos/577585/pexels-photo-577585.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
    thumbnail: 'https://images.pexels.com/photos/577585/pexels-photo-577585.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
  },
  {
    original: 'https://images.pexels.com/photos/270637/pexels-photo-270637.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
    thumbnail: 'https://images.pexels.com/photos/270637/pexels-photo-270637.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
  },
  {
    original: 'https://images.pexels.com/photos/196659/pexels-photo-196659.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
    thumbnail: 'https://images.pexels.com/photos/196659/pexels-photo-196659.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
  },
];

const images2 = [
  {
    original: 'https://images.pexels.com/photos/34600/pexels-photo.jpg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
    thumbnail: 'https://images.pexels.com/photos/34600/pexels-photo.jpg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
  },
  {
    original: 'https://images.pexels.com/photos/3888151/pexels-photo-3888151.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
    thumbnail: 'https://images.pexels.com/photos/3888151/pexels-photo-3888151.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
  },
  {
    original: 'https://images.pexels.com/photos/5077049/pexels-photo-5077049.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
    thumbnail: 'https://images.pexels.com/photos/5077049/pexels-photo-5077049.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
  },
];




const Gallery = () => {
  return (
    <div className="parent py-24 mt-4 ">
      <div style={{marginBottom:'20px'}}>
      <h3 className="text-neutral text-center">Our Moments</h3>
      <h1 className="text-4xl font-semibold drop-shadow-md text-center" style={{marginBottom:'20px'}}>
        Photo<span className="text-primary" >Gallery</span>
      </h1>
      <BottomLine />
      </div>

      <div style={{marginBottom:'30px'}}>
        <h4 className="text-2xl font-semibold drop-shadow-md text-center" style={{marginBottom:'20px'}}>
          <span className="text-primary" >Meetings</span>
        </h4>
        <ImageGallery items={images} />
      </div>
      <div style={{marginBottom:'30px'}}>
        <h4 className="text-2xl font-semibold drop-shadow-md text-center" style={{marginBottom:'20px'}}>
          <span className="text-primary" >Celebrations</span>
        </h4>
        <ImageGallery items={images2} />
      </div>
      
    </div>
  );
};

export default Gallery;
